/**
 * Custom Component - Availabilities
 * @author Charles Harwood
 */
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
.availabilities {
  font-size: 2em;
}

.availabilities__sibling__image {
  width: 100%;
}

@media (min-width: 1200px) {
  .availabilities__sibling {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  .availabilities__sibling__image {
    display: none;
  }
}

@media (max-width: 1199px) {
  .availabilities__sibling {
    background: 0 !important;
  }
}

.availabilities .component-content {
  max-width: 70.2rem;
}

.availabilities header.availabilities__header {
  background: none;
  font-size: 1em;
}

@media (max-width: 767px) {
  .availabilities header.availabilities__header {
    font-size: 0.75em;
    margin: 0;
  }
}

.availabilities h5 {
  margin-top: 0;
  margin-bottom: 3.5rem;
  text-align: center;
  font-size: 2.6em;
  font-family: "Frank Ruhl Libre", serif;
  color: #000;
}

.availabilities .availabilities__header,
.availabilities .availability__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 -15px;
}

.availabilities .availabilities__header > div,
.availabilities .availability__item > div {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  padding: 0 15px;
}

.availabilities .availabilities__header > div.availability-expand,
.availabilities .availability__item > div.availability-expand {
  flex-basis: 24px;
  max-width: 24px;
}

@media (max-width: 767px) {
  .availabilities .availabilities__header > div.availability-expand,
  .availabilities .availability__item > div.availability-expand {
    margin-right: 10px;
    padding: 0;
  }
}

@media (max-width: 767px) {
  .availabilities .availabilities__header,
  .availabilities .availability__item {
    margin: 0;
  }
}

.availabilities .availabilities__header {
  padding: 1.25em 1.5625em;
  border-top: 1px solid #eaebeb;
  border-bottom: 1px solid #eaebeb;
}

.availabilities .availabilities__header > div {
  color: #000;
  font-weight: 700;
}

.availabilities .availability__item {
  flex-wrap: wrap;
  padding: 1.875em 1.5625em;
  border-bottom: 1px solid #eaebeb;
}

.availabilities .availability__item .availability-level {
  color: #000;
  font-weight: 700;
}

.availabilities .availability__item .availability-expand__toggle {
  width: 2.4rem;
  height: 2.4rem;
  padding: 0;
  border: 0;
  background-color: transparent;
  background-image: url("../images/icons/icon-expand.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  cursor: pointer;
  vertical-align: text-top;
  appearance: none;
}

.availabilities .availability__item .availability-info {
  display: none;
  flex-basis: 100%;
  flex-grow: 0;
  margin-top: 3.5rem;
  font-size: 1em;
  line-height: 1.45833em;
}

.availabilities .availability__item.open .availability-expand__toggle {
  background-image: url("../images/icons/icon-collapse.svg");
}

.availabilities .availabilities__cta {
  margin-top: 1rem;
  text-align: center;
}

@media (max-width: 1469px) {
  .availabilities .availability__item {
    font-size: 0.75em;
  }
}

@media (max-width: 575px) {
  .availabilities > .container {
    padding: 0;
  }
  .availabilities .availabilities__header,
  .availabilities .availability__item {
    padding-right: 0;
    padding-left: 0;
  }
}
